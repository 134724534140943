import moment from 'moment';
import React, { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { LinkButton } from '@headway/helix/LinkButton';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { InsuranceLookupFormValues } from '@headway/shared/types/insuranceCapture';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { getAvoInsuranceFormContext } from '@headway/shared/utils/InsuranceLookupUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';

import { useEligibilityLookup } from '../../hooks/useEligibilityLookup';

interface InsuranceLookupVerifyMatchProps {
  user: UserRead;
  onConfirm: () => void;
  onDismiss: () => void;
  eligibilityPostCheck?: (
    values: Omit<InsuranceLookupFormValues, 'email'>,
    patientUser: UserRead | undefined
  ) => Promise<true>;
}
export const InsuranceLookupVerifyMatch: React.FC<
  InsuranceLookupVerifyMatchProps
> = ({
  user,
  onConfirm,
  onDismiss,
  eligibilityPostCheck,
}: InsuranceLookupVerifyMatchProps) => {
  const userInsurance = user.activeUserInsurance;
  const {
    data: eligibilityLookup,
    isLoading: eligibilityLookupQueryIsLoading,
  } = useEligibilityLookup(userInsurance?.latestEligibilityLookup?.id);
  const { isCarriersLoading, carriersById } = useFrontEndCarriers();
  useEffect(() => {
    if (userInsurance && userInsurance.latestEligibilityLookup?.id)
      trackPageView({
        name: 'Add Insurance Fuzzy Match Suggestion Viewed',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(userInsurance.id),
          eligibilityLookupId: userInsurance.latestEligibilityLookup.id,
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
  }, [user.id, userInsurance, userInsurance?.latestEligibilityLookup?.id]);

  if (eligibilityLookupQueryIsLoading || isCarriersLoading) {
    return null;
  }

  const formattedUserInsuranceName = formatPatientName({
    firstName: userInsurance?.userInputFirstName,
    lastName: userInsurance?.userInputLastName,
  });
  const formattedLookupName = formatPatientName({
    firstName: eligibilityLookup?.patientFirstName,
    lastName: eligibilityLookup?.patientLastName,
  });

  const userSelectedCarrier =
    userInsurance?.userInputFrontEndCarrierId && carriersById
      ? carriersById[userInsurance.userInputFrontEndCarrierId]
      : undefined;
  const correctedCarrier =
    eligibilityLookup?.frontEndCarrierId && carriersById
      ? carriersById[eligibilityLookup?.frontEndCarrierId]
      : undefined;

  const carrierMatch = userSelectedCarrier?.name === correctedCarrier?.name;
  const nameMatch = formattedUserInsuranceName === formattedLookupName;
  const dobMatch = moment(userInsurance?.userInputDob).isSame(
    eligibilityLookup?.patientDateOfBirth,
    'day'
  );
  const memberIdMatch =
    userInsurance?.userInputMemberId === eligibilityLookup?.patientMemberId;
  const groupNumberMatch =
    userInsurance?.userInputGroupNumber ===
    eligibilityLookup?.patientGroupNumber;

  const onConfirmButtonPressed = async () => {
    trackEvent({
      name: 'Add Insurance Fuzzy Match Suggestion Accepted',
      properties: {
        patientUserId: user.id,
        userInsuranceId: String(user?.activeUserInsurance?.id),
        insuranceFormContext: getAvoInsuranceFormContext(),
      },
    });
    if (userInsurance?.latestEligibilityLookup?.id) {
      trackEvent({
        name: 'Add Insurance Completed',
        properties: {
          patientUserId: user.id,
          userInsuranceId: String(userInsurance.id),
          eligibilityLookupId: userInsurance.latestEligibilityLookup.id,
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }

    if (eligibilityPostCheck) {
      try {
        await eligibilityPostCheck(
          {
            firstName: eligibilityLookup?.patientFirstName!,
            lastName: eligibilityLookup?.patientLastName!,
            dob: eligibilityLookup?.patientDateOfBirth!,
            frontEndCarrierId:
              eligibilityLookup?.frontEndCarrierId!.toString()!,
            memberId: eligibilityLookup?.patientMemberId!,
            groupNumber: eligibilityLookup?.patientGroupNumber,
            lastSearchedState: user.lastSearchedState!,
          },
          user
        );
      } catch (err) {
        logException(err);
        onDismiss();
        return;
      }
    }
    onConfirm();
  };

  return (
    <>
      <p className="mb-4 mt-0">
        <ContentText>
          {userInsurance?.wasFrontEndCarrierCorrected
            ? 'In verifying your insurance information, we found that your mental health care is potentially through another insurance carrier. ' +
              'In some cases, insurance companies will work with a different entity for administering mental health services.'
            : `After searching ${
                userInsurance?.frontEndCarrierName || 'Your insurer'
              }’s database, we found insurance information similar to what you entered. ` +
              'If this is correct, you can continue. Otherwise, go back and double-check your info against your insurance card.'}
        </ContentText>
      </p>
      <div className="grid grid-cols-2 gap-4 p-2">
        <div className="border-system-borderGray flex flex-col gap-2 rounded border border-solid p-4">
          <div>
            <ContentText variant={'body/medium'}>You entered:</ContentText>
          </div>
          {!!(userSelectedCarrier && correctedCarrier && !carrierMatch) && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Plan</ContentText>
              <p className="m-0">
                <ContentText>{userSelectedCarrier.name}</ContentText>
              </p>
            </div>
          )}
          {!nameMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Name</ContentText>
              <p className="m-0">
                <ContentText>{formattedUserInsuranceName}</ContentText>
              </p>
            </div>
          )}
          {!dobMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">
                Date of Birth
              </ContentText>
              <p className="m-0">
                <ContentText>
                  {moment(userInsurance?.userInputDob).format('MM/DD/YYYY')}
                </ContentText>
              </p>
            </div>
          )}
          {!memberIdMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Member ID</ContentText>
              <p className="m-0">
                <ContentText>{userInsurance?.userInputMemberId}</ContentText>
              </p>
            </div>
          )}
          {!groupNumberMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">
                Group Number
              </ContentText>
              <p className="m-0">
                <ContentText>
                  {userInsurance?.userInputGroupNumber || '-'}
                </ContentText>
              </p>
            </div>
          )}
        </div>
        <div className="border-system-borderGray flex flex-col gap-2 rounded border border-solid p-4">
          <div>
            <ContentText variant={'body/medium'}>
              <span className="font-bold">We found:</span>
            </ContentText>
          </div>
          {!!(userSelectedCarrier && correctedCarrier && !carrierMatch) && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Plan</ContentText>
              <p className="m-0">
                <ContentText>{correctedCarrier.name}</ContentText>
              </p>
            </div>
          )}
          {!nameMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Name</ContentText>
              <p className="m-0">
                <ContentText>{formattedLookupName}</ContentText>
              </p>
            </div>
          )}
          {!dobMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">
                Date of Birth
              </ContentText>
              <p className="m-0">
                <ContentText>
                  {moment(eligibilityLookup?.patientDateOfBirth).format(
                    'MM/DD/YYYY'
                  )}
                </ContentText>
              </p>
            </div>
          )}
          {!memberIdMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">Member ID</ContentText>
              <p className="m-0">
                <ContentText>{eligibilityLookup?.patientMemberId}</ContentText>
              </p>
            </div>
          )}
          {!groupNumberMatch && (
            <div className="flex flex-col">
              <ContentText color="foreground/secondary">
                Group Number
              </ContentText>
              <p className="m-0">
                <ContentText>
                  {eligibilityLookup?.patientGroupNumber ||
                    userInsurance?.groupNumber ||
                    '-'}
                </ContentText>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="mb-8 mt-4 flex flex-col">
        <p className="m-0">
          <ContentText>
            If everything looks correct, select{' '}
            <ContentText variant="body/medium">
              Yes, use this information
            </ContentText>{' '}
            to continue.
          </ContentText>
        </p>
        <p className="m-0">
          <ContentText>
            If you notice a discrepancy, select{' '}
            <ContentText variant="body/medium">Go back and edit</ContentText> to
            make changes.
          </ContentText>
        </p>
        {userInsurance?.wasFrontEndCarrierCorrected && (
          <p className="mb-0">
            <ContentText>
              Need more help?{' '}
              <LinkButton
                type="button"
                variant="link"
                target="_blank"
                href="/contact?primaryIssueType=l1__benefits&secondaryIssueType=l2_inactive_insurance"
              >
                Request an additional verification
              </LinkButton>
              .
            </ContentText>
          </p>
        )}
      </div>
      <div className="text-right">
        <Button type="button" variant="secondary" onPress={onDismiss}>
          Go back and edit
        </Button>
        <span className="ml-4">
          <Button
            type="button"
            variant={'primary'}
            onPress={onConfirmButtonPressed}
          >
            Yes, use this information
          </Button>
        </span>
      </div>
    </>
  );
};
