import React from 'react';

import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { ContentText } from '@headway/helix/ContentText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getAvoInsuranceFormContext } from '@headway/shared/utils/InsuranceLookupUtils';

export const InsuranceOutageGuidanceCard = ({
  userInsurance,
}: {
  userInsurance?: UserInsuranceRead;
}) => {
  const isInOutage = userInsurance?.isInOutage;
  if (!isInOutage) {
    return null;
  }

  React.useEffect(() => {
    if (userInsurance && userInsurance.isInOutage) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: userInsurance.userId,
          userInsuranceId: String(userInsurance.id),
          eligibilityLookupId: userInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType: 'outage',
          insuranceFormContext: getAvoInsuranceFormContext(),
        },
      });
    }
  }, [userInsurance]);

  const payerName = userInsurance?.frontEndCarrierName || 'Your insurer';
  const isInOldDataOutage = userInsurance?.isInOldDataOutage;
  return (
    <GuidanceCard variant="warning" layout={'vertical'}>
      <ContentText>
        {payerName}’s system is experiencing a high volume of plan updates, and
        needs more time to get us the information we need to verify all your
        benefit details. You can still attend sessions at{' '}
        {isInOldDataOutage
          ? 'your last session’s rate'
          : 'a temporary flat rate'}
        .
      </ContentText>
    </GuidanceCard>
  );
};
