import React, { useState } from 'react';

import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { ContentText } from '@headway/helix/ContentText';
import { IconQuestion } from '@headway/helix/icons/Question';
import { Link } from '@headway/helix/Link';

import { InsuranceOutageGuidanceCard } from '../Benefits/InsuranceGuidanceCards/InsuranceOutageGuidanceCard';
import { HowWeEstimateYourCostExpandable } from './components/PriceEstimateSavingsCard';

interface InsuranceOutageCostSummaryProps {
  carrierName: string | undefined;
  priceEstimateString: string;
  isInOldDataOutage: boolean;
  userInsurance?: UserInsuranceRead;
  variant?: 'helix' | 'default';
}

const InsuranceOutageCostSummary = ({
  carrierName = 'Your insurer',
  priceEstimateString,
  isInOldDataOutage,
  userInsurance,
  variant = 'default',
}: InsuranceOutageCostSummaryProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (variant === 'helix') {
    return (
      <div className="flex flex-col gap-5">
        <InsuranceOutageGuidanceCard userInsurance={userInsurance} />
        <div className="flex flex-col gap-2">
          <ContentText variant="body/medium">
            Your temporary session cost with {carrierName} is:
          </ContentText>
          <div className="flex items-center justify-start gap-1">
            <ContentText variant="section-title/medium">
              {priceEstimateString}
            </ContentText>
            <ContentText color="foreground/secondary">per session</ContentText>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <ContentText variant="body/medium">What’s next</ContentText>
          <ContentText>
            {isInOldDataOutage
              ? `You can pay your last session’s rate of ${priceEstimateString} until ${carrierName} confirms your final cost. Note `
              : 'Many clients on Headway pay around this much per session, though note'}{' '}
            that your final cost could be higher or lower. If {carrierName}{' '}
            confirms a different final cost after reviewing your claim, we’ll
            refund or charge your payment method at that time.
          </ContentText>
        </div>
        <HowWeEstimateYourCostExpandable
          isExpanded={isExpanded}
          onToggleExpanded={() => setIsExpanded((prev) => !prev)}
          alignment="left"
        />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      <InsuranceOutageGuidanceCard userInsurance={userInsurance} />
      <div className="border-border-primary border-1 flex flex-col gap-3 rounded border-solid p-4">
        <span>
          <ContentText variant="section-title">
            {priceEstimateString}
            &nbsp;
          </ContentText>
          <ContentText variant="body/medium">
            temporary estimated cost
          </ContentText>
        </span>
        <p>
          <ContentText>
            <strong>
              You can pay{' '}
              {isInOldDataOutage
                ? 'your last session’s rate'
                : 'a temporary flat rate'}{' '}
              of {priceEstimateString}
              {isInOldDataOutage ? '' : ' per session'}{' '}
            </strong>{' '}
            until {carrierName} confirms your final cost.{' '}
            {isInOldDataOutage
              ? 'Note '
              : 'Many clients on Headway pay around this much per session, though note '}
            that your final cost could be higher or lower. If {carrierName}{' '}
            confirms a different final cost after reviewing your claim, we’ll
            refund or charge your payment method at that time.
          </ContentText>
        </p>
        <span className="flex items-center gap-2">
          <IconQuestion />
          <Link
            href="https://help.headway.co/hc/en-us/articles/12494271485972-How-we-estimate-session-costs-and-why-session-costs-may-change#h_01HP23MPZ9JZ12F579G1TD6N54"
            target="_blank"
            rel="noreferrer"
          >
            How will my actual estimate cost be determined?
          </Link>
        </span>
      </div>
    </div>
  );
};

export default InsuranceOutageCostSummary;
