import React from 'react';

import { Button } from '@headway/helix/Button';

import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';

type Props = {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
  onClose: () => void;
};

const InsuranceLookupEmailFollowupImpl: React.FC<Props> = ({
  AuthStore,
  onClose,
}) => {
  return (
    <>
      <p className={'pb-4'}>
        We will need to manually look up your insurance details by calling{' '}
        {AuthStore.user.activeUserInsurance?.frontEndCarrierName}.{' '}
        {
          <>
            Please{' '}
            <a href={'/contact'} target="_blank" rel="noreferrer">
              contact our support team
            </a>{' '}
            and we'll be happy to send you a price estimate.
          </>
        }
      </p>
      <div className="mt-6 flex justify-end">
        <Button variant="primary" onPress={onClose}>
          Got it
        </Button>
      </div>
    </>
  );
};

export default withStores(InsuranceLookupEmailFollowupImpl);
