import clsx from 'clsx';
import { ReactNode, useState } from 'react';

import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { IconCaretDown } from '@headway/helix/icons/CaretDown';
import { IconPiggyBank } from '@headway/helix/icons/PiggyBank';
import { IconQuestion } from '@headway/helix/icons/Question';
import { Link } from '@headway/helix/Link';

export type PriceEstimateSavingsCardProps = {
  priceEstimateDescription: string;
  priceBreakdowns?: PriceBreakdownProps[];
};

const PriceEstimateSavingsCard = ({
  priceEstimateDescription,
  priceBreakdowns = [],
}: PriceEstimateSavingsCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const requiresPriceBreakdown = priceBreakdowns.length > 0;

  return (
    <div className="h-fit w-full self-stretch rounded-[8px] border border-solid border-[#fCEFC5] bg-[#FFFBE6] p-3 text-center">
      <div
        className={clsx(
          'desktop:max-w-none mx-auto flex flex-col items-center justify-center',
          requiresPriceBreakdown ? 'gap-6' : 'gap-4'
        )}
      >
        <div
          className={clsx(
            'flex flex-col items-center justify-center',
            requiresPriceBreakdown ? 'gap-6' : 'gap-3'
          )}
        >
          <div className="flex flex-col items-center justify-center gap-2">
            <IconPiggyBank />
            {requiresPriceBreakdown && (
              <ContentText variant="caption/medium">
                Understand your session cost estimate
              </ContentText>
            )}
          </div>
          {requiresPriceBreakdown && (
            <PriceBreakdownComparison>
              {priceBreakdowns.map((breakdown, index) => (
                <PriceBreakdown
                  key={`${breakdown.title}-${index}`}
                  title={breakdown.title}
                  subtitle={breakdown.subtitle}
                />
              ))}
            </PriceBreakdownComparison>
          )}
          <ContentText variant="body">{priceEstimateDescription}</ContentText>
        </div>
        <HowWeEstimateYourCostExpandable
          isExpanded={isExpanded}
          onToggleExpanded={() => setIsExpanded((prev) => !prev)}
        />
      </div>
    </div>
  );
};

export type PriceBreakdownProps = {
  title: string;
  subtitle: string;
};

const PriceBreakdown = ({ title, subtitle }: PriceBreakdownProps) => {
  return (
    <div className="flex max-w-[120px] flex-col items-center justify-center gap-1 text-center">
      <ContentText variant="section-title/medium">{title}</ContentText>
      <ContentText variant="caption">{subtitle}</ContentText>
    </div>
  );
};

type PriceBreakdownComparisonProps = {
  children: ReactNode;
  description?: string;
};

const PriceBreakdownComparison = ({
  children,
}: PriceBreakdownComparisonProps) => {
  return (
    <div className="flex items-baseline justify-center gap-2">{children}</div>
  );
};

type HowWeEstimateYourCostExpandableProps = {
  isExpanded: boolean;
  onToggleExpanded: () => void;
  alignment?: 'center' | 'left';
};

export const HowWeEstimateYourCostExpandable = ({
  isExpanded,
  onToggleExpanded,
  alignment = 'center',
}: HowWeEstimateYourCostExpandableProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div
        className={clsx('flex cursor-pointer items-center gap-2', {
          'justify-center': alignment === 'center',
          'justify-start': alignment === 'left',
        })}
        onClick={onToggleExpanded}
      >
        <IconQuestion />
        <Button size="medium" variant="link" onPress={onToggleExpanded}>
          How we estimate your cost
        </Button>
        <IconCaretDown
          className={clsx('transition-all', isExpanded && 'rotate-180')}
        />
      </div>
      <div
        className={clsx(
          'flex flex-col items-center justify-center text-left transition-all',
          isExpanded ? 'h-fit' : 'h-0 overflow-hidden'
        )}
      >
        <ContentText variant="body-small">
          Our estimates tend to be accurate, based on our experience with
          hundreds of insurance plans, but occasionally, your insurer may make
          one final adjustment to your cost after reviewing your claim. In these
          cases, we’ll take care of the update on our end by refunding or
          charging the difference automatically.{' '}
          <Link
            target="_blank"
            href="https://help.headway.co/hc/en-us/articles/12494271485972-How-we-estimate-session-costs-and-why-session-costs-may-change#h_01GQ6KSPQQ80SH9H4SX56B7XS2"
          >
            Learn more
          </Link>
        </ContentText>
      </div>
    </div>
  );
};

export default PriceEstimateSavingsCard;
