import { EmailOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';

import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { Divider } from '@headway/helix/Divider';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { IconCreditCard } from '@headway/helix/icons/CreditCard';
import { IconFileText } from '@headway/helix/icons/FileText';
import { Link } from '@headway/helix/Link';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { getPriceString } from '@headway/shared/utils/bookingUtils';

import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import { getProviderPriceCacheKey } from '../../utils/cacheKeys';
import { CareAdvocate } from './icons/CareAdvocate';

type InsuranceLookupConfirmationProps = {
  providerId?: number;
  onClose: () => void;
  AuthStore: IAuthStore;
  UiStore: IUiStore;
};

const InsuranceLookupConfirmationImpl: React.FC<
  InsuranceLookupConfirmationProps
> = ({ providerId, onClose, AuthStore, UiStore }) => {
  const providerPriceQuery = useQuery(
    getProviderPriceCacheKey(providerId!, AuthStore.user),
    () => {
      return ProviderApi.getProviderPrice(providerId!, AuthStore.user.id);
    },
    { staleTime: Infinity, enabled: !!providerId }
  );
  const user = AuthStore.user;
  const isMedicaidUser = user.activeUserInsurance?.isMedicaid;

  function EstimatedCostDescription() {
    React.useEffect(() => {
      trackEvent({
        name: 'Ported Patient Insurance Education Modal Tooltip Hovered/Clicked',
        properties: {},
      });
    }, []);

    return (
      <>
        Your estimated cost might change as we learn more details about your
        coverage, such as whether you've met your deductible.
      </>
    );
  }

  const price = getPriceString(
    providerPriceQuery.data?.firstSessionMinPrice,
    providerPriceQuery.data?.firstSessionMaxPrice
  );

  let estimatedCostComponent = null;
  // Medicaid users should see a $0 price estimate
  if (isMedicaidUser) {
    estimatedCostComponent = (
      <div className={'mb-4 grid gap-y-1'}>
        <ContentText variant={'body/medium'}>
          Your{' '}
          <GlossaryTerm term="estimated cost">
            <EstimatedCostDescription />
          </GlossaryTerm>{' '}
          is:
        </ContentText>
        <div className="flex items-center gap-1">
          <ContentText variant={'section-title/medium'}>$0</ContentText>
          <ContentText color={'foreground/secondary'}>
            {'per session'}
          </ContentText>
        </div>
      </div>
    );
  } else if (providerPriceQuery.isLoading) {
    estimatedCostComponent = (
      <div className={'mb-4 grid gap-y-1'}>
        <Skeleton variant="rectangular" height={22} width="100%" />
        <Divider />
      </div>
    );
  } else if (
    !providerPriceQuery.isError &&
    providerPriceQuery.data &&
    !providerPriceQuery.data.errorState
  ) {
    estimatedCostComponent = (
      <div className={'mb-4 grid gap-y-1'}>
        <ContentText variant={'body/medium'}>
          Your{' '}
          <GlossaryTerm term="estimated cost">
            <EstimatedCostDescription />
          </GlossaryTerm>{' '}
          is:
        </ContentText>
        <div className="flex items-center gap-1">
          <ContentText variant={'section-title/medium'}>{price}</ContentText>
          <ContentText color={'foreground/secondary'}>
            {'per session'}
          </ContentText>
        </div>
      </div>
    );
  }

  React.useEffect(() => {
    trackPageView({
      name: 'Ported Patient Insurance Education Modal Viewed',
      properties: {},
    });
  }, []);

  return (
    <div className="flex h-full flex-col">
      {estimatedCostComponent}
      {!isMedicaidUser && (
        <div className="flex flex-col gap-2">
          <ContentText variant={'body/medium'}>What happens next?</ContentText>
          <div className="flex flex-col gap-2">
            <div className="flex items-start gap-3">
              <div>
                <IconCreditCard />
              </div>
              <ContentText variant={'body-small'}>
                {price ? (
                  <>
                    After each session, we’ll charge your card {price}. This is
                    an estimate based on everything we know about your plan.
                  </>
                ) : (
                  <>
                    Once you book a session, we’ll give you an estimate of your
                    cost based on your provider. This is just an estimate based
                    on everything we know about your plan and we’ll charge that
                    estimate after your session.
                  </>
                )}
              </ContentText>
            </div>
            <div className="flex items-start gap-3">
              <div>
                <IconFileText />
              </div>
              <ContentText variant={'body-small'}>
                {AuthStore.user.activeUserInsurance?.frontEndCarrierName ||
                  'Your insurer'}{' '}
                will review your claim. In some cases, they may determine that
                your final cost is different. This can happen if you haven’t met
                your deductible or out-of-pocket maximum.{' '}
                <Link
                  href="https://findheadway.zendesk.com/hc/en-us/articles/12494271485972-Updates-to-your-session-costs"
                  target="_blank"
                  rel="noreferrer"
                >
                  See why costs can change
                </Link>
                .
              </ContentText>
            </div>
            <div className="flex items-start gap-3">
              <div>
                <EmailOutlined fontSize="small" />
              </div>
              <ContentText variant={'body-small'}>
                If you were overcharged, we’ll refund you the difference. If you
                were undercharged, we’ll send you an explanation and charge your
                card a few days later.
              </ContentText>
            </div>
          </div>
        </div>
      )}

      <div className="bg-hue-lightGreen mt-6 flex items-center gap-4 rounded p-3">
        <div>
          <CareAdvocate className="banner-icon" aria-hidden />
        </div>
        <div>
          <ContentText variant={'body/medium'}>
            Headway helps you navigate your benefits.
          </ContentText>{' '}
          <ContentText>
            We make{' '}
            <Link
              href="/benefits"
              target="_blank"
              rel="noopener noreferrer"
              className="text-system-textBlack underline"
              onClick={() => {
                trackEvent({
                  name: 'Ported Patient Insurance Education Modal Learn More Link Clicked',
                  properties: {},
                });
              }}
            >
              understanding your benefits
            </Link>{' '}
            and what you will owe as easy as possible.
          </ContentText>
        </div>
      </div>
      <div className="mt-8 flex h-full w-full flex-1 items-end justify-end gap-2">
        <Button variant="primary" type="button" onPress={onClose}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default withStores(InsuranceLookupConfirmationImpl);
