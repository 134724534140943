import { useEffect } from 'react';

import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { usePatientGeneralPriceEstimate } from '@headway/shared/hooks/usePatientGeneralPriceEstimate';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getPriceString } from '@headway/shared/utils/bookingUtils';

import { IAuthStore } from '../../stores/withStores';
import InsuranceOutageCostSummary from '../BookAppointmentFlow/InsuranceOutageCostSummary';

interface HwSourcedInsuranceVerificationInProgressProps {
  onClose: (arg?: { openSignupModal?: boolean }) => void;
  authStore: IAuthStore;
}
export const InsuranceVerificationInProgress = ({
  onClose,
  authStore,
}: HwSourcedInsuranceVerificationInProgressProps) => {
  const { user: patient } = authStore;
  const { data: estimatedPrice } = usePatientGeneralPriceEstimate(patient.id);

  useEffect(() => {
    if (patient.activeUserInsurance)
      trackPageView({
        name: 'Verification In Progress Modal Viewed',
        properties: {
          patientUserId: patient.id,
          userInsuranceId: String(patient.activeUserInsurance.id),
          prelimPricingType: String(
            patient.activeUserInsurance.latestOutageType
          ),
          presumptiveAccumulatorsApplied:
            patient.activeUserInsurance?.latestOutage
              ?.accumulatorsPresumedReset ?? [],
        },
      });
  }, [patient.id, patient.activeUserInsurance]);

  const estimatedPriceString = getPriceString(
    estimatedPrice?.min,
    estimatedPrice?.max
  );

  const carrierName = patient?.activeUserInsurance?.frontEndCarrierName;

  return (
    <>
      <InsuranceOutageCostSummary
        variant={'helix'}
        carrierName={carrierName}
        isInOldDataOutage={!!patient?.activeUserInsurance?.isInOldDataOutage}
        priceEstimateString={estimatedPriceString}
        userInsurance={patient?.activeUserInsurance}
      />
      <p>
        <ContentText>
          Alternatively, you can start the booking process, but then choose to{' '}
          <strong>get notified</strong> as soon as your benefits are confirmed
          and book your session later.
        </ContentText>
      </p>
      <div className="mt-6 flex justify-end">
        <Button variant="primary" onPress={() => onClose()}>
          Done
        </Button>
      </div>
    </>
  );
};
