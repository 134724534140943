import React from 'react';

import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { trackEvent } from '@headway/shared/utils/analytics';
import { BenefitsGrid } from '@headway/ui/BenefitsGrid';
import { useMarkets } from '@headway/ui/providers/MarketProvider';
import { notifySuccess } from '@headway/ui/utils/notify';

import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import {
  trackInsuranceEmailRequestForCarrier,
  trackInsuranceEmailRequestForMarket,
} from '../../utils/analytics';

interface InsuranceLookupOutOfNetworkImplProps {
  AuthStore: IAuthStore;
  onBack: () => void;
  onClose: () => void;
  UiStore: IUiStore;
}

const InsuranceLookupOutOfNetworkImpl: React.FC<
  InsuranceLookupOutOfNetworkImplProps
> = ({ AuthStore, onBack, onClose }) => {
  React.useEffect(() => {
    trackEvent({
      name: 'Insurance_lookup_failed',
      properties: {},
    });
  }, []);
  const { markets } = useMarkets();

  const onSignup = () => {
    const carrierId = AuthStore.user.activeUserInsurance?.frontEndCarrierId;
    const isMarketLive = markets.some(
      (market) =>
        market.marketplaceLive &&
        market.state === AuthStore.user.lastSearchedState
    );
    if (AuthStore.user.lastSearchedState && !isMarketLive) {
      trackInsuranceEmailRequestForMarket(
        AuthStore.user.email!,
        AuthStore.user.lastSearchedState
      );
    } else if (carrierId) {
      trackInsuranceEmailRequestForCarrier(AuthStore.user.email!, carrierId);
    }
    notifySuccess(
      'We’ve received your request and we’ll email you as soon as we accept your insurance.'
    );
    onClose();
  };

  return (
    <>
      <BenefitsGrid user={AuthStore.user} onUpdateInsuranceClick={onBack} />
      <p>
        <ContentText>
          But let’s keep in touch — you’ll be the first to know when we can take
          your insurance in your area.
        </ContentText>
      </p>
      <div className="mt-6 flex justify-end">
        <Button variant="primary" onPress={onSignup}>
          Let me know
        </Button>
      </div>
    </>
  );
};

export default withStores(InsuranceLookupOutOfNetworkImpl);
