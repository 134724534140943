import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

export const getEligibilityLookupCacheKey = (eligibilityLookupId: number) =>
  ['eligibilityLookup', eligibilityLookupId] as const;

export const useEligibilityLookup = (
  eligibilityLookupId?: number,
  options?: UseQueryOptions<
    EligibilityLookupRead,
    unknown,
    EligibilityLookupRead,
    ReturnType<typeof getEligibilityLookupCacheKey>
  >
) =>
  useQuery(
    getEligibilityLookupCacheKey(eligibilityLookupId!),
    () => EligibilityLookupApi.getEligibilityLookup(eligibilityLookupId!),
    {
      ...options,
      enabled: !!eligibilityLookupId && options?.enabled,
      staleTime: Infinity,
    }
  );
