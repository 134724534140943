import React from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { InsuranceLookupFormError } from '@headway/shared/types/insuranceCapture';
import { trackPageView } from '@headway/shared/utils/analytics';

import { IAuthStore, IUiStore, withStores } from '../../stores/withStores';
import InsuranceLookupConfirmation from './InsuranceLookupConfirmation';
import InsuranceLookupEmailFollowup from './InsuranceLookupEmailFollowup';
import {
  InsuranceAuthorizationInstructionsModalContent,
  InsuranceLookupForm,
} from './InsuranceLookupForm';
import InsuranceLookupIssueFound from './InsuranceLookupIssueFound';
import InsuranceLookupOutOfNetwork from './InsuranceLookupOutOfNetwork';
import { InsuranceLookupVerifyMatch } from './InsuranceLookupVerifyMatch';
import { InsuranceVerificationInProgress } from './InsuranceVerificationInProgress';

enum Page {
  Form = 'form',
  Confirmation = 'confirmation',
  IssueFound = 'issue_found',
  EmailFollowup = 'email_followup',
  OutOfNetwork = 'out_of_network',
  VerificationInProgress = 'verification_in_progress',
  InsuranceAuthorizationInstructions = 'insurance_authorization_instructions',
  VerifyFuzzyMatch = 'verify_fuzzy_match',
}

const PAGE_TITLE_MAP: {
  [key in Page]: string | React.JSX.Element;
} = {
  [Page.Form]: 'My Insurance Information',
  [Page.Confirmation]: 'We added your insurance plan', // Customize title
  [Page.IssueFound]: 'Insurance issue',
  [Page.EmailFollowup]: 'Get price over email',
  [Page.OutOfNetwork]: "We don't take your insurance, yet",
  [Page.VerificationInProgress]: 'Your plan is still being verified',
  [Page.InsuranceAuthorizationInstructions]: 'Requesting a referral',
  [Page.VerifyFuzzyMatch]: 'Did you mean this?',
};

type Props = {
  AuthStore: IAuthStore;
  onClose: (args?: { openSignupModal?: boolean }) => void;
  open: boolean;
  providerId?: number;
  UiStore: IUiStore;
};

type State = {
  open: boolean;
  page: Page;
  providerCarriers: ProviderFrontEndCarrierRead[];
  carrierToShowAuthzInstructionsFor?: FrontEndCarrierRead;
  overrideAsManual: boolean;
};

class InsuranceLookupModalImpl extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      open: this.props.open,
      page: Page.Form,
      providerCarriers: [],
      overrideAsManual: false,
    };
  }

  async componentDidMount() {
    if (!this.props.providerId) {
      this.setState({ providerCarriers: [] });
      return;
    }

    const providerCarriers =
      await ProviderFrontEndCarrierApi.getProviderFrontEndCarriers({
        provider_id: this.props.providerId,
      });

    this.setState({ providerCarriers: providerCarriers });
  }

  setPage = (page: Page) => {
    this.setState({ page });
  };

  setCarrierToShowAuthzInstructionsFor = (carrier: FrontEndCarrierRead) => {
    this.setState({ carrierToShowAuthzInstructionsFor: carrier });
  };

  render() {
    const { providerId, open, onClose, AuthStore } = this.props;
    const { page } = this.state;
    const user = AuthStore.user;

    let pageTitle = PAGE_TITLE_MAP[page];

    const onModalClose = () => {
      this.setPage(Page.Form);
      onClose();
    };

    if (
      page === Page.InsuranceAuthorizationInstructions &&
      !!this.state.carrierToShowAuthzInstructionsFor
    ) {
      return (
        <Modal isOpen={true} onDismiss={onModalClose} title={pageTitle}>
          <InsuranceAuthorizationInstructionsModalContent
            carrier={this.state.carrierToShowAuthzInstructionsFor}
            closeModal={onModalClose}
          />
        </Modal>
      );
    }

    const goBackOnInsuranceError = () => {
      this.setPage(Page.Form);
      this.setState({ overrideAsManual: true });
    };

    return (
      open && (
        <Modal
          variant={'content'}
          isOpen={open}
          onOpenChange={(isOpen) => {
            if (isOpen) {
              trackPageView({
                name: 'Enter Insurance Modal',
                properties: {},
              });
            }
          }}
          onDismiss={onModalClose}
          onBack={onModalClose}
          title={pageTitle}
        >
          <ModalContent>
            {page === Page.Form && (
              <InsuranceLookupForm
                enableOcr={true}
                isInsideModal={true}
                onSuccess={() => {
                  this.setPage(Page.Confirmation);
                }}
                onError={(error) => {
                  let nextPage;
                  switch (error) {
                    case InsuranceLookupFormError.OUT_OF_NETWORK:
                      nextPage = Page.OutOfNetwork;
                      break;
                    case InsuranceLookupFormError.VERIFICATION_IN_PROGRESS:
                      nextPage = Page.VerificationInProgress;
                      break;
                    case InsuranceLookupFormError.VERIFY_FUZZY_MATCH:
                      nextPage = Page.VerifyFuzzyMatch;
                      break;
                    default:
                      nextPage = Page.IssueFound;
                      break;
                  }
                  this.setPage(nextPage);
                }}
                showInsuranceAuthorizationInstructions={(carrier) => {
                  this.setPage(Page.InsuranceAuthorizationInstructions);
                  this.setCarrierToShowAuthzInstructionsFor(carrier);
                }}
                onCancel={onModalClose}
                providerId={providerId}
                providerCarriers={this.state.providerCarriers}
                showSecondaryLink
                overrideAsManual={this.state.overrideAsManual}
                includeHeading={false}
                includeFooter={false}
              />
            )}
            {page === Page.Confirmation && (
              <InsuranceLookupConfirmation
                providerId={providerId}
                onClose={onModalClose}
              />
            )}
            {page === Page.VerificationInProgress && (
              <InsuranceVerificationInProgress
                authStore={AuthStore}
                onClose={onModalClose}
              />
            )}
            {page === Page.IssueFound && (
              <InsuranceLookupIssueFound
                onBack={goBackOnInsuranceError}
                onConfirm={() => this.setPage(Page.EmailFollowup)}
              />
            )}
            {page === Page.EmailFollowup && (
              <InsuranceLookupEmailFollowup onClose={onClose} />
            )}
            {page === Page.OutOfNetwork && (
              <InsuranceLookupOutOfNetwork
                onBack={goBackOnInsuranceError}
                onClose={onModalClose}
              />
            )}
            {page === Page.VerifyFuzzyMatch && (
              <InsuranceLookupVerifyMatch
                user={user}
                onDismiss={goBackOnInsuranceError}
                onConfirm={() => this.setPage(Page.Confirmation)}
              />
            )}
          </ModalContent>
        </Modal>
      )
    );
  }
}

const InsuranceLookupModal = withStores(InsuranceLookupModalImpl);

export default InsuranceLookupModal;
