import React from 'react';

// TODO: Remove once CareAdvocate icon is added to Helix
export const CareAdvocate = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.83919 18.7296L1.3799 24.2429C1.16105 24.4047 1.09218 24.7015 1.21746 24.9431L4.42762 31.1342C4.58146 31.4308 4.96106 31.5247 5.2354 31.3338L8.67958 28.9379C10.0589 27.9784 11.7505 27.577 13.4138 27.8146L21.9631 29.0359C22.5691 29.1225 23.1849 28.9675 23.6777 28.6044L31.0762 23.1529C32.0802 22.4131 32.1735 20.9464 31.2714 20.0853C30.5783 19.4237 29.5142 19.3443 28.7306 19.8958L23.3252 23.6995C23.0542 23.8903 22.7309 23.9926 22.3995 23.9926H17.7413C17.1686 23.9926 16.6487 23.6578 16.4117 23.1364L16.1593 22.5812C15.8379 21.8742 16.3548 21.0715 17.1314 21.0715H20.0362C20.8893 21.0715 21.5809 20.3799 21.5809 19.5267C21.5809 18.6876 20.911 18.0019 20.0721 17.9824L11.8061 17.7902C10.7403 17.7654 9.69652 18.0959 8.83919 18.7296Z"
        stroke="#128756"
        stroke-width="1.10744"
      />
      <path
        d="M16.9316 15.3402L14.0736 12.9282C11.7329 10.9463 8.69922 8.68417 8.69922 5.32316C8.69922 2.93554 10.665 1 13.0898 1C14.3869 1 15.5527 1.55385 16.3564 2.43449C16.6473 2.75312 17.2159 2.75312 17.5067 2.43449C18.3105 1.55385 19.4762 1 20.7734 1C23.1982 1 25.1639 2.93554 25.1639 5.32316C25.1639 8.68398 22.1307 10.9457 19.7902 12.9276L16.9316 15.3402ZM16.9316 15.3402L19.7889 12.9287"
        stroke="#128756"
        stroke-width="1.10744"
        stroke-linecap="round"
      />
    </svg>
  );
};
