/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* this enum maps to the only potential value for the Client Issue Type zendesk field. */import {EnumMetadata} from '../metadata'

    /**
    * this enum maps to the only potential value for the Client Issue Type zendesk field.
    */
export enum ClientIssueType {
    ACCOUNT_ACCESS_APPOINTMENT_HISTORY = 'rit_account_access_appointment_history',
    INSURANCE_VERIFICATION = 'rit_benefits___eligibility',
    BILLING_CHARGES = 'rit_billing___charges',
    CLAIMS = 'rit_claims',
    FINDING_A_PROVIDER = 'rit_finding_a_provider',
    HEALTH_SPENDING_ACCOUNTS = 'rit_healthcare_cost-saving_benefits',
    LEAVING_HEADWAY = 'rit_leaving_headway',
    PRODUCT_ISSUES = 'rit_technical_issues',
    BILLING_INSURANCE_ISSUES = 'rit_billing___insurance_issues',
    OTHER = 'rit_other',
    COMPLAIN_ABOUT_A_PROVIDER = 'rit_complain_about_a_provider'
}


export const ClientIssueTypeMetadata: EnumMetadata<ClientIssueType> = {
    name: 'ClientIssueType',
    values: {
            ACCOUNT_ACCESS_APPOINTMENT_HISTORY: 'rit_account_access_appointment_history',
            INSURANCE_VERIFICATION: 'rit_benefits___eligibility',
            BILLING_CHARGES: 'rit_billing___charges',
            CLAIMS: 'rit_claims',
            FINDING_A_PROVIDER: 'rit_finding_a_provider',
            HEALTH_SPENDING_ACCOUNTS: 'rit_healthcare_cost-saving_benefits',
            LEAVING_HEADWAY: 'rit_leaving_headway',
            PRODUCT_ISSUES: 'rit_technical_issues',
            BILLING_INSURANCE_ISSUES: 'rit_billing___insurance_issues',
            OTHER: 'rit_other',
            COMPLAIN_ABOUT_A_PROVIDER: 'rit_complain_about_a_provider'
    }
}
